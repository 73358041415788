import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { Form, Input, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
  import { get, post } from "../../../../services/apiCall";
import Loader from "../../../../components/Loader";
function AddEcoblue() {
  const location = useLocation()
  const [form] = Form.useForm();
  const breadcrumbItems = [
    { label: "User Management" },
    { label: "Ecoblue-Sub-Admin", url: "/ecoblue/all" },
    { label: location?.pathname === "/ecoblue/add" ? "Add Ecoblue" : "Edit Ecoblue" },
  ];

  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const ecoblueId = params.get('id');
  const isEditMode = (ecoblueId) ? true : false;
  const url = (isEditMode) ? "update-single-ecoblue?id="+ecoblueId : "add-ecoblue-admin";

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if(values.password == undefined) {
        values.password = '';
      }
      const response = await post('/ecoblue/'+url, values);
      if (response.status) {
        form.resetFields();
        navigate("/ecoblue/all");
        message.success(response.message);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error('Error in registration:', error);
    } finally {
      setLoading(false);
    }
  };
 
  useEffect(() => {
    if(isEditMode){
      getEcoblueSubAdminDetails();
    }else{
      setUserData({})
    }
  }, []);

  const [userData, setUserData] = useState(null);
  const getEcoblueSubAdminDetails = async () => {
    try {
      setLoading(true);
      const response = await get(`/ecoblue/get-single-ecoblue?id=${ecoblueId}`);
      if (response.status) {
        setUserData(response.data)
      } else {
        message.error(response.message);
        navigate('/ecoblue/all');
      }
    } catch (error) {
      console.error('Error fetching ecoblue sub admin details:', error);
    } finally {
      setLoading(false);
    }    
  };

  return (
    <div className="container-fluid">
      <Breadcrumbs items={breadcrumbItems} />
      <div className="row">
        <div className="col-lg-10 col-xl-8">
          <div className="form-card form-sm-custom-style mb-4">
            <div className="form-heading  mb-4">
              <p className="primary-color text-decoration-underline fw-500 fs-22">{location?.pathname === "/ecoblue/add" ? "Add " : "Edit "} Ecoblue sub admin</p>
            </div>
            {userData ? (
            <Form
              form={form}
              name="ecoblueSubadmin"
              initialValues={userData}
              onFinish={handleSubmit}
              layout="vertical"
              autoComplete="on"
              requiredMark={false}
            >
              <div className="row">
                <div className="col-md-6">
                  <Form.Item name="firstName" label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter first name!",
                      },
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item name="lastName" label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter last name!",
                      },
                    ]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Form.Item name="email" label="Email Address"
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Please enter email!",
                      },
                    ]}
                  >
                    <Input placeholder="example@gmail.com" />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item name="phoneNumber" label="Phone Number"
                    rules={[
                      {
                        type: "phoneNumber",
                        required: false,
                        message: "Please enter phone number!",
                      },
                    ]}
                  >
                    <Input placeholder="+234 09-461-4000" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Form.Item name="password" label="Set Password"
                    rules={[
                      {
                        required: isEditMode ? false : true,
                        message: "Please enter password!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-12">
                  <button type="submit" className="form-btn">{loading ? <Loader /> :  (!isEditMode) ? "Add Ecoblue-Sub-Admin" : "Update Ecoblue-Sub-Admin" } </button>
                </div>
              </div>
            </Form>
            )  : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEcoblue;
