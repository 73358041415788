import React, { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { IoMdAddCircleOutline } from "react-icons/io";
import { message, Popconfirm } from "antd";
import TableComponents from "../../../../components/Table/TableComponents";
import TablePagination from "../../../../components/Table/TablePagination";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import { get } from "../../../../services/apiCall";
import BodyLoader from "../../../../components/BodyLoader";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
function AllSubadmins() {

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Change as needed
  const [totalPages, setTotalPages] = useState(0);
  const [searchData, setSearchData] = useState('');
  const [isAssigned, setIsAssigned] = useState(1);
  const [loading, setLoading] = useState(false)

  const startIndex = (currentPage - 1) * itemsPerPage;

  const breadcrumbItems = [
    { label: "User Management" },
    { label: "Sub-Admin" },
  ];
  const navigate = useNavigate();

  const handleConfirm = async (_id) => {
    try {
      const response = await get(`/subadmin/delete-subadmin?id=${_id}`);
      if (response.status) {
        message.success(response.message);
        fetchAllSubAdminsList();
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const cancel = (e) => {
    console.log(e);
  };

  useEffect(() => {
    fetchAllSubAdminsList();
  }, [currentPage, searchData, itemsPerPage]);

  const handleDataForSearchTableComponent = (data) => {
    setSearchData(data);
    setCurrentPage(1);
  };

  const sendPerPageCountToGenerateNylons = (count) => {
    setItemsPerPage(count);
    setCurrentPage(1);
  };

  const fetchAllSubAdminsList = async () => {
    setLoading(true);
    try {
      const response = await get(`/subadmin/subadmin-listing?page=${currentPage}&limit=${itemsPerPage}&searchText=${searchData}`);
      if (response.status) {
        setData(response.data);
        setTotalPages(response.pagination.totalPages);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date) => {
    const originalDate = new Date(date);
    const formattedDate = `${originalDate.getFullYear()}-${(originalDate.getMonth() + 1).toString().padStart(2, '0')}-${originalDate.getDate().toString().padStart(2, '0')} ${originalDate.getHours().toString().padStart(2, '0')}:${originalDate.getMinutes().toString().padStart(2, '0')}:${originalDate.getSeconds().toString().padStart(2, '0')}`;
    return formattedDate;
  }


  return (
    <div className="container-fluid">
      {(loading) ? <BodyLoader /> : ''}
      <Breadcrumbs items={breadcrumbItems} />
      <div className="row">
        <div className="col-md">
          <TableComponents handlePerPageCountToGenerateNylons={sendPerPageCountToGenerateNylons} sendDataToGenerateNylons={handleDataForSearchTableComponent} />
        </div>
        <div className="col-md-auto">
          <button
            className="primary-btn"
            onClick={() => {
              navigate("/subadmin/add");
            }}
          >
            <IoMdAddCircleOutline className="me-2 fs-22 mb-1" />
            Add Sub-Admin
          </button>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-md-12">
          <div className="table-wrapper table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Date Added</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {(data.length > 0) ?
                  data.map((element, index) => {
                    return (
                      <tr key={index + 1}>
                        <td key={index}>{startIndex + index + 1}</td>
                        <td>{element.firstName ? element.firstName : ''} {element.lastName ? element.lastName : ''}</td>
                        <td>{element.email ? element.email : ''}</td>
                        <td className="ws-nowrap">{formatDate(element.createdAt)}</td>
                        <td>
                          <div className="tableAction-wrapper">
                            <button
                              className="tableAction-btn btn-view"
                              onClick={() => navigate("/subadmin/details?id="+element._id)}
                            >
                              <AiFillEye />
                            </button>
                            <button
                              className="tableAction-btn btn-edit"
                              onClick={() => navigate("/subadmin/edit?id="+element._id)}
                            >
                              <MdEdit />
                            </button>
                            <Popconfirm
                              title="Delete the task"
                              description="Are you sure to delete this Subadmin account?"
                              onConfirm={() => handleConfirm(element._id)}
                              onCancel={cancel}
                              okText="Yes"
                              cancelText="No"
                            >
                              <button type="button" className="tableAction-btn btn-delete">
                                <RiDeleteBin6Fill />
                              </button>
                            </Popconfirm>
                          </div>
                        </td>
                      </tr>
                    );
                  }) :
                  <tr>
                    <td colSpan="4" className="text-center">No record found!</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {(totalPages > 0) ?
        <div className="row">
          <div className="col-md-12">
            <div className="pagination-wrapper mb-2">
              <span className={currentPage > 1 ? 'active-span-bg pointer' : ''} onClick={() => {
                if (currentPage === 1) {
                  return false;
                }
                setCurrentPage(currentPage - 1);
              }}><FaLongArrowAltLeft className={currentPage > 1 ? 'active-span-svg' : ''} /></span>
              <span>{currentPage}/{totalPages}</span>
              <span className={currentPage == totalPages ? 'inactive-span-bg' : 'pointer'} onClick={() => {
                if (currentPage === totalPages) {
                  return false;
                }
                setCurrentPage(currentPage + 1);
              }}><FaLongArrowAltRight className={currentPage == totalPages ? 'inactive-span-svg' : ''} /></span>
            </div>
          </div>
        </div>
        : ''}
    </div>
  );
}


export default AllSubadmins;
