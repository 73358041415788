import React, { useEffect, useState } from "react";
import { Form, Input, message } from "antd";
import { get, post } from "../../services/apiCall";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";


function WasteMgmtAgencyForm({ handleAgencyExists, handleIsEditAgent, isEdit}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [wmagencyData, setWMAgencyData] = useState({});
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if (values.password == undefined) {
        values.password = '';
      }
      const response = await post('/wasteagency/add-wasteagency/', values);
      if (response.status) {
        setWMAgencyData(response.data)
        handleAgencyExists(true);
        if(isEdit){
          handleIsEditAgent(false)
        }
        form.resetFields();
        // navigate("/waste-management-agency/add-staff");
        message.success(response.message);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error('Error in waste-management-agency registration:', error);
    } finally {
      setLoading(false);
    }
  };



  return (
    <div className="form-card form-sm-custom-style mb-4">
      <div>
        <p className="primary-color fw-500 fs-22 text-decoration-underline">
          {!isEdit ? "Add" : "Edit"} Waste Management Agency
        </p>
      </div>
      <Form
        form={form}
        name="login"
        initialValues={{
          remember: true,
        }}
        // onFinish={() => {
        //   handleAgencyExists(true);
        //   if(isEdit){
        //     handleIsEditAgent(false)
        //   }
        // }}
        onFinish={handleSubmit}
        layout="vertical"
        autoComplete="off"
        requiredMark={false}
      >
        <div className="row">
          <div className="col-md-6">
            <Form.Item name="firstName" label="First Name"
            rules={[
              {
                required: true,
                message: "Please enter first name!",
              },
            ]}
          >
              <Input placeholder="First Name" />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item name="lastName" label="Last Name" 
            rules={[
              {
                required: true,
                message: "Please enter last name!",
              },
            ]}
          >
              <Input placeholder="Last Name" />
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Form.Item name="email" label="Email Address" 
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter email!",
              },
            ]}
          >
              <Input placeholder="example@gmail.com" />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item name="phoneNumber" label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please enter phone number!",
              },
            ]}
          >
              <Input placeholder="+234 09-461-4000" />
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Form.Item name="password" label="Set Password" 
            rules={[
              {
                required: true,
                message: "Please enter password!",
              },
            ]}
          >
              <Input.Password placeholder="Password" />
            </Form.Item>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-12">
            {/* <button className="form-btn">
              {!isEdit ? "Add Agency" : "Save"}{" "} 
            </button> */}
            <button type="submit" className="form-btn">{loading ? <Loader /> : "Add Agency"}</button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default WasteMgmtAgencyForm;
