import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { FaRegUser } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { BiPhone } from "react-icons/bi";
import WasteCollectionVerified from "../../../../components/subadmin/WasteCollectionVerified";
import TicketsResolved from "../../../../components/subadmin/TicketsResolved";
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import { get } from "../../../../services/apiCall";
import BodyLoader from "../../../../components/BodyLoader";

function EcoblueDetails() {
  const breadcrumbItems = [
    { label: "User Management" },
    { label: "Ecoblue-Sub-Admin", url: "/ecoblue/all" },
    { label: "Ecoblue Subadmin Details" },
  ];
  const [activeTab, setActiveTab] = useState("wasteCollection");
  const renderTabDetails = () => {
    switch (activeTab) {
      case "wasteCollection":
        return <WasteCollectionVerified />;
      case "resolvedTickets":
        return <TicketsResolved />;
      default:
        return <WasteCollectionVerified />;
    }
  };

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getEcoblueSubAdminDetails();
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const ecoblueId = params.get('id');
  const [data, setData] = useState([]);



  const getEcoblueSubAdminDetails = async () => {
    try {
      setLoading(true);
      const response = await get(`/ecoblue/get-single-ecoblue?id=${ecoblueId}`);
      if (response.status) {
        setData(response.data)
      } else {
        message.error(response.message);
        navigate('/ecoblue/all');
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="container-fluid">
      {loading ? <BodyLoader /> : ''}
      <Breadcrumbs items={breadcrumbItems} />
      <div className="row">
        <div className="col-md-12">
          {(data.firstName || data.lastName) ?
            <p className="heading-color fs-16 fw-500 mb-3">
              <FaRegUser className="me-3 fs-20 primary-color" />
              {data.firstName} {data.lastName}
            </p>
            : ''}
          {(data.email) ?
            <p className="heading-color fs-16 fw-500 mb-3">
              <FiMail className="me-3 fs-20 primary-color" />
              {data.email}
            </p>
            : ''}
          {(data.phoneNumber) ?
            <p className="heading-color fs-16 fw-500 mb-3">
              <BiPhone className="me-3 fs-20 primary-color" />
              {data.phoneNumber}
            </p>
            : ''}
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="tabs-container">
            <ul className="tabs">
              <li
                className={`${activeTab === "wasteCollection" ? "active" : ""
                  } tab`}
                onClick={() => {
                  setActiveTab("wasteCollection");
                }}
              >
                Waste Collection Verified
              </li>
              <li
                className={`${activeTab === "resolvedTickets" ? "active" : ""
                  } tab`}
                onClick={() => {
                  setActiveTab("resolvedTickets");
                }}
              >
                Tickets Resolved
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {
            renderTabDetails()
          }
        </div>
      </div>
    </div>
  );
}

export default EcoblueDetails;
