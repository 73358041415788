import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Input, message } from "antd";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { get, post } from "../../../../services/apiCall";
import Loader from "../../../../components/Loader";
const { TextArea } = Input;

function AddVendor() {
  const location = useLocation();
  const [form] = Form.useForm();
  const breadcrumbItems = [
    { label: "User Management" },
    { label: "Vendor", url: "/vendor/all" },
    {
      label:
        location?.pathname === "/vendor/add"
          ? "Add Vendor"
          : "Edit Vendor",
    },
  ];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const vendorId = params.get('id');

  const isEditMode = (vendorId) ? true : false;
  const url = (isEditMode) ? "update-single-vendor?id=" + vendorId : "add-vendor";

  useEffect(() => {
    if (isEditMode) {
      getVendorDetails();
    } else {
      setUserData({})
    }
  }, []);

  const handleSubmitForm = async (values) => {
    try {
      setLoading(true);
      if(values.password == undefined) {
        values.password = '';
      }
      const response = await post('/vendor/' + url, values);
      if (response.status) {
        form.resetFields();
        navigate("/vendor/all");
        message.success(response.message);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error('Error in registration:', error);
    } finally {
      setLoading(false);
    }
  };

  const getVendorDetails = async () => {
    try {
      setLoading(true);
      const response = await get(`/vendor/get-single-vendor?id=${vendorId}`);
      if (response.status) {
        setUserData(response.data)
      } else {
        message.error(response.message);
        navigate('/subadmin/all');
      }
    } catch (error) {
      console.error('Error fetching sub admin details:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <Breadcrumbs items={breadcrumbItems} />
      <div className="row">
        <div className="col-lg-10 col-xl-8">
          <div className="form-card form-sm-custom-style mb-4">
            <div className="form-heading  mb-4">
              <p className="primary-color fw-500 fs-22 text-decoration-underline">
                {location?.pathname === "/vendor/add" ? "Add " : "Edit "}
                Vendor
              </p>
            </div>
            {userData ? (
              <Form
                form={form}
                name="vendorForm"
                initialValues={userData}
                onFinish={handleSubmitForm}
                layout="vertical"
                autoComplete="on"
                requiredMark={false}
              >
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item name="firstName" label="First Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter first name!",
                        },
                      ]}>
                      <Input placeholder="First Name" />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item name="lastName" label="Last Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter last name!",
                        },
                      ]}>
                      <Input placeholder="Last Name" />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item name="email" label="Email Address"
                      rules={[
                        {
                          type: "email",
                          required: true,
                          message: "Please enter email!",
                        },
                      ]}>
                      <Input placeholder="example@gmail.com" />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item name="phoneNumber" label="Phone Number"
                      rules={[
                        {
                          required: true,
                          message: "Please enter phone number!",
                        },
                      ]}>
                      <Input placeholder="+234 09-461-4000" />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Item name="companyName" label="Company Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter company name!",
                        },
                      ]}>
                      <Input placeholder="Company Name" />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Item name="companyAddress" label="Company Address"
                      rules={[
                        {
                          required: true,
                          message: "Please enter company address!",
                        },
                      ]}>
                      <TextArea rows={4} placeholder="Company Address" />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Item name="password" label="Set Password"
                      rules={[
                        {
                          required: isEditMode ? false : true,
                          message: "Please enter password!",
                        },
                      ]}>
                      <Input.Password placeholder="Password" />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Item name="website" label="Website (if any)">
                      <Input placeholder="Website" />
                    </Form.Item>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-12">
                    <button type="submit" className="form-btn">{loading ? <Loader /> : (!isEditMode) ? "Add Vendor" : "Update Vendor"} </button>
                  </div>
                </div>
              </Form>
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddVendor;
