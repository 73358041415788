import React, { useEffect, useState } from 'react'
import { Form, Input, message } from "antd";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { passwordValidator } from '../../services/validation';
import { get, post } from '../../services/apiCall';
import Loader from '../../components/Loader';

function ResetPassword() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const myToken = params.get('token');

  useEffect(() => {
    verifyResetToken();
  }, []);


  const verifyResetToken = async () => {
    try {

      const roleResponse = await post('/auth/verify-reset-token', ({ 'token': myToken }));
      if (roleResponse.status) {
        message.success(roleResponse.message)
      } else {
        message.error(roleResponse.message)
        navigate("/login");
      };
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };



  const confirmPasswordValidator = (_, value) => {
    const passwordFieldValue = form.getFieldValue("newPassword");
    if (!value || passwordFieldValue === value) {
      return Promise.resolve();
    }   
    return Promise.reject(new Error("Passwords do not match"));
  };

   // State to hold the form data
   const [formData, setFormData] = useState({
    inputValue: ''
  });

  // Function to handle form submission
  const onHandleForm = async (values) => {
    try {
      // Do something with the form data, for example, set a new value
      values.token = myToken;
      const response = await post('/auth/update-password', values);
      if (response.status) {
        message.success(response.message);
        navigate("/login");
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error('Error logging in:', error);
    } finally {
      setLoading(false);
    }
  };

  
  return (
    <div className="container-fluid auth-page-bg">
      <div className="row h-100">
        <div className="col-md-5 auth-page-left">
          <div className="row h-100">
            <div className="col-md-12 logo-heading">
              {/* <p className="logo-heading">WasteTrack</p> */}
              <img src="/images/logo.png" alt="logo-img" className="img-fluid" />
            </div>
            <div className="col-md-12 auth-page-left-bottom">
              <img
                src="/images/loginPageImg.png"
                alt="login-img"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <div className="col-md-7 auth-form-right">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-7 auth-page-form">
              <div>
                <p className="heading-1">Reset Password</p>

              </div>
              <div className='mt-5 form-custom-style'>
                <Form
                  form={form}
                  name="resetPassword"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onHandleForm}
                  layout="vertical"
                  autoComplete="on"
                  requiredMark={false}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Item name="newPassword" label="New password" rules={[
                        {
                          required: true,
                          message: "Enter new password!",
                          whitespace: true,
                        },
                        { validator: passwordValidator },
                      ]}>
                        <Input.Password placeholder="Enter New Password" />
                      </Form.Item>
                    </div>

                    <div className="col-md-12">
                      <Form.Item name="confirmPassword" label="Confirm Password" rules={[
                        {
                          required: true,
                          message: "Confirm your password!",
                        },
                        {
                          validator: confirmPasswordValidator,
                        },

                      ]}>
                        <Input.Password placeholder="Re-enter Password" />
                      </Form.Item>
                    </div>

                    <div className="col-md-12 py-4">
                      <button type="submit"
                        className="auth-form-btn btn-100">
                        {loading ? <Loader /> : "Confirm"}
                      </button>
                    </div>
                    <div className="col-md-12 text-center">
                      <Link to="/login" className="fs-18 fw-500 primary-color">
                        Go to login page
                        <span>
                          <HiOutlineArrowNarrowRight className="fs-4" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword