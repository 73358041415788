import "./style/main.scss";
import React from "react";
import AdminRoutes from "./panelRoutes/AdminRoutes";
import SubadminRoutes from "./panelRoutes/SubadminRoutes";
import VendorRoutes from "./panelRoutes/VendorRoutes";
import VendorStaffRoutes from "./panelRoutes/VendorStaffRoutes";
import WasteManagementStaffRoutes from "./panelRoutes/WasteManagementStaffRoutes";
import WasteManagementAgencyRoutes from "./panelRoutes/WasteManagementAgencyRoutes";
import GovtAgencyRoutes from "./panelRoutes/GovtAgencyRoutes";
import { useSelector } from "react-redux";
import DefaultRoutes from "./panelRoutes/DefaultRoutes";

function App() {

  const roleId = useSelector((state)=>state?.auth?.user?.roleInfo?.roleId)
  
    switch (roleId) {
      case '1':
        return <AdminRoutes/>
      case "2":
        return <SubadminRoutes/>
      case "3":
        return <VendorRoutes/>;
      case "4":
        return <VendorStaffRoutes/>;
      case "5":
        return <GovtAgencyRoutes/>
      case "wasteMgmtStaff":
        return <WasteManagementStaffRoutes/>;
      case "wasteMgmtAgency":
        return <WasteManagementAgencyRoutes/>;
      default : 
        return <DefaultRoutes/>
    }

}
export default App;
