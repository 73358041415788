import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import WasteMgmtAgencyForm from "../../../../components/usermanagement/WasteMgmtAgencyForm";
import { Form, Input, Upload } from "antd";
import { FiCamera } from "react-icons/fi";
import { FaRegUser } from "react-icons/fa";
import { RxEnvelopeClosed } from "react-icons/rx";
import { BsEyeFill } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Popconfirm, message } from 'antd';
import { get, post } from "../../../../services/apiCall";
import BodyLoader from "../../../../components/BodyLoader";
import Loader from "../../../../components/Loader";

function WasteManagementAgency() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const breadcrumbItems = [
    { label: "User Management" },
    { label: "Waste Management Agency" },
  ];
  const [isAgencyExists, setIsAgencyExists] = useState(false);
  const [isEditAgent, setIsEditAgent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const location = useLocation();
  const [wmAgencyStaffData, setWMAgencyStaffData] = useState({});

  useEffect(() => {
    fetchWMAgencyIfRegistered();
    // if (isAgencyExists) {
    //   debugger
    //   navigate("/waste-management-agency");
    // }
  }, [isAgencyExists]);
  const handleConfirm = async (_id) => {
    try {
      const response = await get(`/wasteagency/delete-wasteagency?id=${_id}`);
      if (response.status) {
        setIsAgencyExists(false);
        navigate("/waste-management-agency");
        message.success(response.message);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setLoading(false);
    }
  };

  // const handleSubmitWMStaff = async (values) => {
  //   setLoading(true);
  //   try { 
  //     if (values.password == undefined) {
  //       values.password = '';
  //     }
  //     values.vendorId = data._id; // data._id;
  //     const response = await post('/wasteagency/add-wasteagency-staff', values);
  //     if (response.status) {
  //       setWMAgencyStaffData(response.data)
  //       form.resetFields();
  //       message.success(response.message);
  //     } else {
  //       message.error(response.message);
  //     }
  //   } catch (error) {
  //     console.error('Error in waste-management-agency-staff registration:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const cancel = (e) => {
    console.log(e);
  };

  const fetchWMAgencyIfRegistered = async () => {
    setLoading(true);
    try {
      const response = await get("/wasteagency/get-single-wasteagency");
      if (response.status) {
        if (response.data) {
          setData(response.data);
          setIsAgencyExists(true);
        } else {
          setIsAgencyExists(false);
        }
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setLoading(false);
    }
  };



  return (
    <div className="container-fluid">
      {(loading) ? <BodyLoader /> : ''}
      <Breadcrumbs items={breadcrumbItems} />
      <div className="row">
        <div className="col-lg-4 my-2">
          {isAgencyExists ? (
            <div className="panel-card">
              <div className="card-body">
                <div className="text-center">
                  <div className="d-inline-block my-3 position-relative">
                    <img
                      src={(data.imageName) ? data.imageBaseUrl + data.imageName : "/images/userLg.png"}
                      alt="user-img"
                      className="profileFrm-UserImg"
                    />
                    <Upload>
                      <button className="profileupload-camera">
                        <FiCamera />
                      </button>
                    </Upload>
                  </div>
                </div>
                <div>
                  <p className="text-center fs-20 fw-600">
                    Waste Management Agency Name
                  </p>
                  <ul className="list-unstyled mb-0">
                    {(data.firstName || data.lastName) ?
                      <li>
                        <p className="fw-500 fs-16 heading-color">
                          <FaRegUser className="primary-color fs-5 me-2" />
                          {data.firstName} {data.lastName}
                        </p>
                      </li>
                      : ''}

                    {(data.email) ?
                      <li className="overflow-auto">
                        <p className="fw-500 fs-16 heading-color">
                          <RxEnvelopeClosed className="primary-color fs-5 me-2" />
                          {data.email}
                        </p>
                      </li>
                      : ''}
                  </ul>
                </div>
                <div className="row">
                  <div className="col p-1">
                    <button
                      className="button-view w-100"
                      onClick={() => {
                        navigate("/waste-management-agency-details");
                      }}
                    >
                      <BsEyeFill className="mb-1" /> View
                    </button>
                  </div>
                  <div className="col p-1">
                    <button
                      className="button-edit w-100"
                      onClick={() => {
                        setIsEditAgent(true);
                      }}
                    >
                      <MdModeEdit className="mb-1" />
                      Edit
                    </button>
                  </div>
                  <div className="col p-1">
                    <Popconfirm
                      title="Delete the task"
                      description="Are you sure to delete this Agency account?"
                      onConfirm={() => handleConfirm(data._id)}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button className="button-delete w-100">
                        <RiDeleteBin6Fill className="mb-1" />
                        Delete
                      </button>
                    </Popconfirm>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="panel-card p-5">
              <div className="card-body">
                <div className="text-center">
                  <img
                    src="/images/noData.png"
                    alt="noDataImg"
                    className="img-fluid"
                  />
                  <p className="fw-600 fs-20">
                    No Waste Management Agency Added
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-lg-8 my-2">
          {isAgencyExists ? (
            isEditAgent ? (
              <WasteMgmtAgencyForm
                handleAgencyExists={setIsAgencyExists}
                handleIsEditAgent={setIsEditAgent}
                isEdit={isEditAgent}
              />
            ) : (
              <Outlet/>
              // <div className="form-card form-sm-custom-style mb-4">
              //   <div className="form-heading">
              //     <p className="primary-color fw-500 fs-22 text-decoration-underline">{location?.pathname === "/waste-management-agency/add-staff" ? "Add " : "Edit "} Waste Management Staff</p>
              //   </div>
              //   <Form
              //     form={form}
              //     name="/wmastaff"
              //     initialValues={{
              //       remember: true,
              //     }}
              //     onFinish={handleSubmitWMStaff}
              //     layout="vertical"
              //     autoComplete="off"
              //     requiredMark={false}
              //   >
              //     <div className="row">
              //       <div className="col-md-6">
              //         <Form.Item name="firstName" label="First Name"
              //           rules={[
              //             {
              //               required: true,
              //               message: "Please enter first name!",
              //             },
              //           ]}
              //         >
              //           <Input placeholder="First Name" />
              //         </Form.Item>
              //       </div>
              //       <div className="col-md-6">
              //         <Form.Item name="lastName" label="Last Name"
              //           rules={[
              //             {
              //               required: true,
              //               message: "Please enter last name!",
              //             },
              //           ]}
              //         >
              //           <Input placeholder="Last Name" />
              //         </Form.Item>
              //       </div>
              //     </div>
              //     <div className="row">
              //       <div className="col-md-6">
              //         <Form.Item name="email" label="Email Address"
              //           rules={[
              //             {
              //               required: true,
              //               message: "Please enter email!",
              //             },
              //           ]}
              //         >
              //           <Input placeholder="example@gmail.com" />
              //         </Form.Item>
              //       </div>
              //       <div className="col-md-6">
              //         <Form.Item name="phoneNumber" label="Phone Number"
              //           rules={[
              //             {
              //               required: true,
              //               message: "Please enter phone number!",
              //             },
              //           ]}
              //         >
              //           <Input placeholder="+234 09-461-4000" />
              //         </Form.Item>
              //       </div>
              //     </div>
              //     <div className="row">
              //       <div className="col-md-12">
              //         <Form.Item name="password" label="Set Password"
              //           rules={[
              //             {
              //               required: true,
              //               message: "Please enter password!",
              //             },
              //           ]}
              //         >
              //           <Input.Password placeholder="Password" />
              //         </Form.Item>
              //       </div>
              //     </div>
              //     <div className="row ">
              //       <div className="col-md-12">
              //         <button type="submit" className="form-btn">{loading ? <Loader /> : location?.pathname === "/waste-management-agency/add-staff" ? "Add Staff" : "Save"} </button>
              //       </div>
              //     </div>
              //   </Form>
              // </div>
            )
          ) : (
            <WasteMgmtAgencyForm handleAgencyExists={setIsAgencyExists} />
          )}
        </div>
      </div>
    </div>
  );
}

export default WasteManagementAgency;
