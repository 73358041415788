import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { IoBagCheckOutline } from "react-icons/io5";
import { PiCurrencyNgnDuotone, PiMoneyFill } from "react-icons/pi";
import { get, post } from "../../../services/apiCall";
import { message } from "antd";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";

function NylonGeneration() {
  const breadcrumbItems = [
    { label: "Nylon Management" },
    { label: "Nylon Generation" },
  ];

  const [availableQty, setavailableQty] = useState(0);
  const [currentPrice, setcurrentPrice] = useState(0);
  const [currentPriceId, setcurrentPriceId] = useState(0);
  const [newPrice, setnewPrice] = useState(0);
  const [quantity, setquantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [generateQtyLoading, setgenerateQtyLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchNylonQuanityPrice();
  }, []);

  const fetchNylonQuanityPrice = async () => {
    try {
      const priceQuantityData = await get('/nylon/nylon-price-qty');
      if (priceQuantityData.status) {
        setavailableQty(priceQuantityData.data.availableQty);
        setcurrentPrice(priceQuantityData.data.currentPrice);
        setnewPrice(priceQuantityData.data.currentPrice);
        setcurrentPriceId(priceQuantityData.data._id);
      } else {
        message.error(priceQuantityData.message);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const handleSetPrice = async () => {
    try {
      setLoading(true);
      const response = await post('/nylon/update-nylon-price', {
        id: currentPriceId,
        newPrice: newPrice
      });
      if (response.status) {
        setcurrentPrice(newPrice);
        message.success(response.message);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error('Error setting price:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleGeneragteQty = async () => {
    try {
      setgenerateQtyLoading(true);
      const response = await post('/nylon/generate-nylon-rolls', {
        quantity: quantity
      });
      if (response.status) {
        //add new qunatity in existing availableQty variabl
        setavailableQty(parseInt(availableQty) + parseInt(quantity));
        //set again to zero in input field
        setquantity(0);
        navigate("/nylon/details");
        message.success(response.message);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error('Error setting price:', error);
    } finally {
      setgenerateQtyLoading(false);
    }
  }


  return (
    <div className="container-fluid">
      <Breadcrumbs items={breadcrumbItems} />
      <div className="row">
        <div className="col-md-6 col-xl-4 my-2">
          <div className="nylonGeneration-top h-100">
            <div className="nylonGeneration-topLeft">
              <p>{availableQty}</p>
              <p>Nylon Rolls Available</p>
            </div>
            <div className="nylonGeneration-topRight">
              <IoBagCheckOutline className="bag-icon" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-4 my-2">
          <div className="nylonGeneration-top h-100">
            <div className="nylonGeneration-topLeft">
              <p>
                <PiCurrencyNgnDuotone className="mb-3" />
                {currentPrice}
              </p>
              <p>Current Nylon Price</p>
            </div>
            <div className="nylonGeneration-topRight">
              <PiMoneyFill />
            </div>
          </div>
        </div>
      </div>
      <div className="row my-3">
        <div className="col-md-6 col-xl-4 my-2">
          <div className="nylonGeneration-bottom h-100">
            <p className="fs-22 fw-500 heading-color">Generate Nylon Rolls</p>
            <div className="text-center">
              <img
                src="/images/nylonBag.png"
                className="img-fluid"
                alt="blackBag"
              />
            </div>
            <div className="mt-4">
              <div className="row align-items-center">
                <div className="col-auto ">
                  <span className="gray-color fs-18 ">Enter Quantity</span>
                </div>
                <div className="col">
                  <input name="quantity" className="custom-input w-100"
                    value={quantity}
                    onChange={(e) => setquantity(e.target.value)} />
                </div>
              </div>
              <button className="primary-btn w-100 mb-3 mt-3" onClick={handleGeneragteQty} >{generateQtyLoading ? <Loader /> : "Generate"}</button>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-4  my-2">
          <div className="nylonGeneration-bottom h-100">
            <p className="fs-22 fw-500 heading-color">Generate Nylon Rolls</p>
            <div className="text-center">
              <img
                src="/images/nairaCurrency.png"
                className="img-fluid"
                alt="blackBag"
              />
            </div>
            <div className="mt-4">
              <div className="row align-items-center">
                <div className="col-auto ">
                  <span className="gray-color fs-18 ">Set Nylon Price</span>
                </div>

                <div className="col">
                  <div className="setPrice-input">
                    <span>
                      <PiCurrencyNgnDuotone />
                    </span>
                    <input name="newPrice" className="w-100"
                      value={newPrice}
                      onChange={(e) => setnewPrice(e.target.value)} />
                  </div>
                </div>
              </div>
              <button className="primary-btn w-100 mb-3 mt-3" onClick={handleSetPrice} >{loading ? <Loader /> : "Set"}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NylonGeneration;
