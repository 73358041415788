import React, { useEffect, useRef, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { BiSolidDownArrow } from 'react-icons/bi';
import { get } from '../../services/apiCall';
import { message } from 'antd';
import Loader from '../Loader';
import BodyLoader from '../BodyLoader';


function VendorListAutoComplete({ getSelectedVendorID }) {
  const [value, setValue] = useState('');
  const [showContainerCss, setShowContainerCss] = useState(false);
  const [suggestionsList, setSuggestionsList] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [valueIsBlank, setValueIsBlank] = useState(false)
  
  const inputRef = useRef(null);

  useEffect(() => {
    setSuggestionsList(fetchedData);
    inputRef.current.focus();
  }, [fetchedData]);


  const fetchData = async (inputValue) => {
    try {
      const response = await get(`/vendor/search-vendor?searchText=${inputValue}`);
      const data = await response.data;
      const mappedData = data.map(item => ({ label: item.companyName, customId: item._id, vendorData: item }));
      if(mappedData.length === 0){
        setValueIsBlank(true);
        setShowContainerCss(false);
      }else{
        setValueIsBlank(false);
        setShowContainerCss(true);
      }
      setFetchedData(mappedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const onSuggestionsClearRequested = () => {
    setSuggestionsList([]);
  };


  const onChange = (event, { newValue }) => {
    setValue(newValue);
    setValueIsBlank(false);
    setShowContainerCss(true);
    setShowContainerCss(false);
  };

  const handleKeyUp = (event) => {
    const { value: inputValue } = event.target;
    setValue(inputValue);

    if (inputValue.trim().length > 0 && event.key !== 'Enter') {
      setLoading(true);
      fetchData(inputValue);
    }
    inputRef.current.focus();
  };

  const inputProps = {
    ref: inputRef,
    placeholder: 'Search Vendor',
    value,
    onChange,
    onKeyUp: handleKeyUp,
    className: "primary-color search-input",
  };

  const theme = {
    container: {position: 'relative',paddingLeft: '0px'},
    input: {border: 'none',padding: '4px',paddingLeft: '31px',width: '100%'},
    suggestionsContainer: {position: 'absolute',top: '100%',width: '100%',zIndex: 1,backgroundColor: 'white',borderRadius: '2px',marginTop: '2px',border: showContainerCss ? '1px solid #73AE6E' : 'none',paddingLeft: '0px'},
    suggestion: {cursor: 'pointer',listStyleType: 'none', padding: '5px 0 5px 5px'},
    suggestionHighlighted: {backgroundColor: '#73AE6E',color: '#fff',}
  };
  const noRecordFoundTheme = {
    container: {position: 'relative', paddingLeft: '16px', width: '100%', height: '38px', marginTop: '0px',border:'1px solid #73AE6E',paddingTop:'7px' }
  };

  return (
    <div>
      {/* {(loading) ? (<BodyLoader />) :  */}
        <Autosuggest
          suggestions={suggestionsList}
          onSuggestionsFetchRequested={() => { }}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={suggestion => suggestion.label}
          renderSuggestion={suggestionsList => <div>{suggestionsList.label}</div>}
          inputProps={inputProps}
          onSuggestionSelected={(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
            getSelectedVendorID(suggestion.vendorData);
          }}
          theme={theme}
        />
      {/* } */}
      {(valueIsBlank) ? 
      <div style={noRecordFoundTheme.container}>
        <span>No record found!</span>
      </div>
      :''}
    </div>
  );
};
export default VendorListAutoComplete;