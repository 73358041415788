// PDFGenerator.js

import React from 'react';
import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    padding: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  heading: {
    fontSize: 24,
    marginBottom: 10,
  },
  content: {
    fontSize: 12,
  },
});

// Create a component to generate PDF
const DownloadTemplatePDF = () => (
  <PDFViewer width="100%" height="600px">
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.heading}>Sample PDF Document</Text>
          <Text style={styles.content}>This is a sample PDF document generated using react-pdf.</Text>
        </View>
      </Page>
    </Document>
  </PDFViewer>
);

export default DownloadTemplatePDF;
