import React, { useEffect, useState } from "react";
import { Form, Input, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { post } from "../../services/apiCall";
import Loader from "../Loader";

function CreateWMStaff() {
  const [form] = Form.useForm();
  const location = useLocation()
  const [loading, setLoading] = useState(false);
  const [wmAgencyStaffData, setWMAgencyStaffData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    debugger
  }, []);

  const handleSubmitWMStaff = async (values) => {
    setLoading(true);
    try {
      debugger
      if (values.password == undefined) {
        values.password = '';
      }
      values.vendorId =  'te'; // data._id;
      const response = await post('/wasteagency/add-wasteagency-staff', values);
      if (response.status) {
        setWMAgencyStaffData(response.data)
        form.resetFields();
        message.success(response.message);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error('Error in waste-management-agency-staff registration:', error);
    } finally {
      setLoading(false);
    }
  };



  return (
    <div className="form-card form-sm-custom-style mb-4">
      <div className="form-heading">
        <p className="primary-color fw-500 fs-22 text-decoration-underline">{location?.pathname === "/waste-management-agency/add-staff" ? "Add " : "Edit "} Waste Management Staff</p>
      </div>
      <Form
        form={form}
        name="/wmastaff"
        initialValues={{
          remember: true,
        }}
        onFinish={handleSubmitWMStaff}
        layout="vertical"
        autoComplete="off"
        requiredMark={false}
      >
        <div className="row">
          <div className="col-md-6">
            <Form.Item name="firstName" label="First Name"
             rules={[
              {
                required: true,
                message: "Please enter first name!",
              },
            ]}
          >
              <Input placeholder="First Name" />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item name="lastName" label="Last Name"
             rules={[
              {
                required: true,
                message: "Please enter last name!",
              },
            ]}
          >
              <Input placeholder="Last Name" />
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Form.Item name="email" label="Email Address"
             rules={[
              {
                required: true,
                message: "Please enter email!",
              },
            ]}
          >
              <Input placeholder="example@gmail.com" />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item name="phoneNumber" label="Phone Number"
             rules={[
              {
                required: true,
                message: "Please enter phone number!",
              },
            ]}
          >
              <Input placeholder="+234 09-461-4000" />
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Form.Item name="password" label="Set Password" 
             rules={[
              {
                required: true,
                message: "Please enter password!",
              },
            ]}
          >
              <Input.Password placeholder="Password" />
            </Form.Item>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-12">
            <button type="submit" className="form-btn">{loading ? <Loader /> : location?.pathname === "/waste-management-agency/add-staff" ? "Add Staff" : "Save"} </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default CreateWMStaff;
