import React, { useEffect, useState } from "react";
import { Form, Input, Select, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from "react-icons/hi";
import Loader from "../../components/Loader";
import { get, post } from "../../services/apiCall";

function ForgotPassword() {

  const [form] = Form.useForm();
  const [roleOptions, setRoleOptions] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const roleResponse = await get('/roles');
      if (roleResponse.status) {
        const roles = roleResponse.data.map(role => ({
          value: role._id,
          label: role.roleName
        }));
        setRoleOptions(roles);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      const loginResponse = await post('/auth/forget-password', values);
      if (loginResponse.status) {
        message.success(loginResponse.message);
        navigate("/login");
      } else {
        message.error(loginResponse.message);
      }
    } catch (error) {
      console.error('Error logging in:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid auth-page-bg">
      <div className="row h-100">
        <div className="col-md-5 auth-page-left">
          <div className="row h-100">
            <div className="col-md-12 logo-heading">
              <img src="/images/logo.png" alt="logo-img" className="img-fluid" />
              {/* <p className="logo-heading">WasteTrack</p> */}
            </div>
            <div className="col-md-12 auth-page-left-bottom">
              <img
                src="/images/loginPageImg.png"
                alt="login-img"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <div className="col-md-7 auth-form-right">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-7 auth-page-form">
              <div>
                <p className="heading-1">Forgot Password</p>
                <p className="fs-18 gray-color">
                  Enter your email and we'll send you a link to get back into
                  your account.
                </p>
              </div>
              <div className="mt-5 form-custom-style">
                <Form
                  form={form}
                  name="forgotPassword"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={handleFormSubmit}
                  layout="vertical"
                  autoComplete="on"
                  requiredMark={false}
                >
                  <div className="row ">
                    <div className="col-md-12">
                      <Form.Item
                        name="roleId"
                        label="Select Account"
                        // extra="Select your account type to get started with the password recovery process."
                        rules={[
                          {
                            required: true,
                            message: "Please select account!",
                          },
                        ]}

                      >
                        <Select
                          placeholder="Select Account"
                          value=""
                          options={roleOptions}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-12">
                      <Form.Item
                        name="email"
                        label="Email Address"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email!",
                          },
                          {
                            type: "email",
                            message: "Please input valid email!",
                          },
                        ]}
                      >
                        <Input placeholder="example@gmail.com" />
                      </Form.Item>
                    </div>

                    <div className="col-md-12 py-4">
                      <button type="submit"
                        className="auth-form-btn btn-100">
                        {loading ? <Loader /> : "Send login link"}
                      </button>
                    </div>
                    <div className="col-md-12 text-center">
                      <Link to="/login" className="fs-18 fw-500 primary-color">
                        Back to login
                        <span>
                          <HiOutlineArrowNarrowLeft className="fs-4 ms-3" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
