import React, { useState } from "react";
import { Select } from "antd";
import { FiSearch } from "react-icons/fi";
import { BiFilterAlt, BiSolidDownArrow } from "react-icons/bi";

function TableComponents({ sendDataToGenerateNylons, handlePerPageCountToGenerateNylons }) {

  const [searchVal, setSearchVal] = useState('');
  const [selectedPagCount, setSelectedPagCount] = useState("10");
  const sendData = (e) => {
    setSearchVal(e.target.value);
    // Call the function passed from the parent with the dataToSend as argument
    sendDataToGenerateNylons(e.target.value);
  };

  const handlePageCountSelection = (count)=>{
    setSelectedPagCount(count);
    handlePerPageCountToGenerateNylons(count);
  }


  return (
    <div className="row align-items-center justify-content-between tableTop-wrapper">
      <div className="col-md-auto my-2 page-selector">
        <Select
          suffixIcon={<BiSolidDownArrow className="primary-color" />}
          value={selectedPagCount}
          onChange={handlePageCountSelection}
          options={[
            {
              value: "10",
              label: "10 / Page",
              className : "pointer"
            },
            {
              value: "50",
              label: "50 / Page",
              className : "pointer"
            },
            {
              value: "100",
              label: "100 / Page",
              className : "pointer"
            },
            {
              value: "500",
              label: "500 / Page",
              className : "pointer"
            },
            {
              value: "1000",
              label: "1000 / Page",
              className : "pointer"
            }  
          ]}
        />
      </div>
      <div className="col-md-auto">
        <div className="row">
          <div className="col-md-auto my-2">
            <div className="search-input-container">
              <span className="search-icon">
                <FiSearch className="primary-color" />
              </span>
              <input
                type="text"
                onChange={sendData}
                value={searchVal}
                placeholder="Search here..."
                className="search-input"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableComponents;
