import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL + "/api",
  rejectUnauthorized: false,
  requestCert: false,
  agent: false,
  headers: { 
    "Access-Control-Allow-Origin": "*",
  },
});

// Function to make a GET request with authorization token
export const get = async (url) => {
  try {
    const token = localStorage.getItem('authToken');
    if (token) {
      apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    const response = await apiClient.get(url);
    return response.data??''; 
  } catch (error) {
    throw error;
  }
};

// Function to make a POST request with authorization token
export const post = async (url, data) => {
  try {
    const token = localStorage.getItem('authToken');
    if (token) {
      apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    const response = await apiClient.post(url, data);
    return response.data??''; 

  } catch (error) {
    throw error;
  }
};

export default apiClient;
