import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { AiFillEye } from "react-icons/ai";
import { FiPlusCircle } from "react-icons/fi";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Popconfirm, message } from "antd";
import TableComponents from "../../../components/Table/TableComponents";
import TablePagination from "../../../components/Table/TablePagination";
import { MdEdit } from "react-icons/md";
import { get } from "../../../services/apiCall";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import BodyLoader from "../../../components/BodyLoader";

function Allvendors() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Change as needed
  const [totalPages, setTotalPages] = useState(0);
  const [searchData, setSearchData] = useState('');
  const [isAssigned, setIsAssigned] = useState(1);
  const [loading, setLoading] = useState(false)

  const startIndex = (currentPage - 1) * itemsPerPage;


  const breadcrumbItems = [
    { label: "Vendor Management" },
    { label: "All vendors" },
  ];
  const confirm = (e) => {
    console.log(e);
    message.success("Click on Yes");
  };
  const cancel = (e) => {
    
  };

  useEffect(() => {
    fetchAllSubAdminsList();
  }, [currentPage, searchData, itemsPerPage]);

  const handleDataForSearchTableComponent = (data) => {
    setSearchData(data);
    setCurrentPage(1);
  };

  const sendPerPageCountToGenerateNylons = (count) => {
    setItemsPerPage(count);
    setCurrentPage(1);
  };

  const fetchAllSubAdminsList = async () => {
    setLoading(true);
    try {
      const response = await get(`/vendor/vendor-listing?page=${currentPage}&limit=${itemsPerPage}&searchText=${searchData}&status=1`);
      if (response.status) {
        setData(response.data);
        setTotalPages(response.pagination.totalPages);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleConfirm = async (_id) => {
    try {
      const response = await get(`/vendor/delete-vendor?id=${_id}`);
      if (response.status) {
        message.success(response.message);
        fetchAllSubAdminsList();
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date) => {
    const originalDate = new Date(date);
    const formattedDate = `${originalDate.getFullYear()}-${(originalDate.getMonth() + 1).toString().padStart(2, '0')}-${originalDate.getDate().toString().padStart(2, '0')} ${originalDate.getHours().toString().padStart(2, '0')}:${originalDate.getMinutes().toString().padStart(2, '0')}:${originalDate.getSeconds().toString().padStart(2, '0')}`;
    return formattedDate;
  }


  return (
    <div className="container-fluid">
      {(loading) ? <BodyLoader /> : ''}
      <Breadcrumbs items={breadcrumbItems} />
      <div className="row ">
        <div className="col">
          <TableComponents handlePerPageCountToGenerateNylons={sendPerPageCountToGenerateNylons} sendDataToGenerateNylons={handleDataForSearchTableComponent} />
        </div>
        <div className="col-auto">
          <button
            className="primary-btn me-2 my-1"
            onClick={() => {
              navigate("/vendor/add");
            }}
          >
            <FiPlusCircle className="mb-1 me-2" />
            Add Vendor
          </button>
          <button
            className="primary-btn  my-1"
            onClick={() => {
              navigate("/vendor/add-staff");
            }}
          >
            <FiPlusCircle className="mb-1 me-2" />
            Add Vendor Staff
          </button>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-md-12">
          <div className="table-wrapper table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Code</th>
                  <th>Company Name</th>
                  <th>Company Address</th>
                  <th>Email</th>
                  <th>Contact Name</th>
                  <th>Date Added</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {(data.length > 0) ?
                  data.map((element, index) => {
                    return (
                      <tr key={index + 1}>
                        <td key={index}>{startIndex + index + 1}</td>
                        <td>{element.vendorCode}</td>
                        <td>{element.companyName}</td>
                        <td>{element.companyAddress}</td>
                        <td>{element.email}</td>
                        <td>{element.phoneNumber}</td>
                        <td className="ws-nowrap">{formatDate(element.createdAt)}</td>
                        <td>
                          <div className="tableAction-wrapper">
                            <button className="tableAction-btn btn-view"
                              onClick={() => navigate("/vendor/details?id=" + element._id)}
                            >
                              <AiFillEye />
                            </button>
                            <button
                              className="tableAction-btn btn-edit"
                              onClick={() => navigate("/vendor/edit?id=" + element._id)}
                            >
                              <MdEdit />
                            </button>
                            <Popconfirm
                              title="Delete the task"
                              description="Are you sure to delete this vendor?"
                              onConfirm={() => handleConfirm(element._id)}
                              onCancel={cancel}
                              okText="Yes"
                              cancelText="No"
                            >
                              <button className="tableAction-btn btn-delete">
                                <RiDeleteBin6Fill />
                              </button>
                            </Popconfirm>
                          </div>
                        </td>
                      </tr>
                    );
                  }) :
                  <tr>
                    <td colSpan="7" className="text-center">No record found!</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {(totalPages > 0) ?
        <div className="row">
          <div className="col-md-12">
            <div className="pagination-wrapper mb-2">
              <span className={currentPage > 1 ? 'active-span-bg pointer' : ''} onClick={() => {
                if (currentPage === 1) {
                  return false;
                }
                setCurrentPage(currentPage - 1);
              }}><FaLongArrowAltLeft className={currentPage > 1 ? 'active-span-svg' : ''} /></span>
              <span>{currentPage}/{totalPages}</span>
              <span className={currentPage == totalPages ? 'inactive-span-bg' : 'pointer'} onClick={() => {
                if (currentPage === totalPages) {
                  return false;
                }
                setCurrentPage(currentPage + 1);
              }}><FaLongArrowAltRight className={currentPage == totalPages ? 'inactive-span-svg' : ''} /></span>
            </div>
          </div>
        </div>
        : ''}
    </div>
  );
}

export default Allvendors;
