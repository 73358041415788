import React, { useEffect, useState } from "react";
import TableComponents from "../../components/Table/TableComponents";
import { AiFillEye } from "react-icons/ai";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { TbDownload } from "react-icons/tb";
import TablePagination from "../../components/Table/TablePagination";
import { Checkbox, message, Popconfirm } from "antd";
import { useNavigate } from "react-router-dom";
import { get, post } from "../../services/apiCall";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import BodyLoader from "../BodyLoader";


function GeneratedNylons() {
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Change as needed
  const [totalPages, setTotalPages] = useState(0);
  const [searchData, setSearchData] = useState('');
  const [isAssigned, setIsAssigned] = useState(0);
  const [loading, setLoading] = useState(false);

  const startIndex = (currentPage - 1) * itemsPerPage;

  const formatDate = (date) => {
    const originalDate = new Date(date);
    const formattedDate = `${originalDate.getFullYear()}-${(originalDate.getMonth() + 1).toString().padStart(2, '0')}-${originalDate.getDate().toString().padStart(2, '0')} ${originalDate.getHours().toString().padStart(2, '0')}:${originalDate.getMinutes().toString().padStart(2, '0')}:${originalDate.getSeconds().toString().padStart(2, '0')}`;
    return formattedDate;
  }

  const [selectAll, setSelectAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [batchId, setBatchId] = useState([]);

  // Function to toggle select all checkboxes
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allIds = data.map(item => item._id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  // Function to toggle individual checkbox
  const handleCheckboxChange = id => {
    if (selectedIds.includes(id)) {
      const updatedIds = selectedIds.filter(item => item !== id);
      setSelectedIds(updatedIds);
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };


  // Function to handle download with selected IDs
  const handleDownload = () => {
    // Perform download action using selectedIds
    console.log('Downloading selected IDs:', selectedIds);
  };


  const handleDownloadPdf = async (id) => {
    var dd = [];
    
    if (id.length > 0) {
      let batchIds = [id];
      dd = { 'batchIds': batchIds };
      
    } else if (selectedIds.length > 0) {
      dd = { 'batchIds': selectedIds };
    } else {
      message.error("Please select at lest one batchID!");
      return false;
    }
    setLoading(true);
    
    try {
      const response = await post("/nylon/generate-batch-qrcode-pdf", dd);
      if (response.status) {
        window.open(response.data.url, '_blank');
        // message.success(response.message);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setSelectedIds([]);
      setLoading(false);
    }
  }

  const handleDataForSearchTableComponent = (data) => {
    setSearchData(data);
    // setItemsPerPage(10);
    setCurrentPage(1);
  };

  const sendPerPageCountToGenerateNylons = (count) => {
    setItemsPerPage(count);
    setCurrentPage(1);
  };

  const handleConfirm = async (_id) => {
    try {
      const response = await get(`/nylon/delete-nylon-batch?batchId=${_id}`);
      if (response.status) {
        message.success(response.message);
        fetchGeneratedNylonList();
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setLoading(false);
    }
  };

  const cancel = (e) => {
    console.log(e);
    message.error("Click on No");
  };

  useEffect(() => {
    fetchGeneratedNylonList();
  }, [currentPage, searchData, itemsPerPage]);

  const fetchGeneratedNylonList = async () => {
    setLoading(true);
    try {
      const nylonsLists = await get(`/nylon/nylon-batch-listing?is_assigned=${isAssigned}&page=${currentPage}&limit=${itemsPerPage}&searchText=${searchData}`);
      if (nylonsLists.status) {
        setData(nylonsLists.data);
        setTotalPages(nylonsLists.pagination.totalPages);
      } else {
        message.error(nylonsLists.message);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {(loading) ? <BodyLoader /> : ''}
      <TableComponents handlePerPageCountToGenerateNylons={sendPerPageCountToGenerateNylons} sendDataToGenerateNylons={handleDataForSearchTableComponent} />
      <div className="row my-4">

        <div className="col-md-12 text-center">
          <button onClick={handleDownloadPdf} className="primary-btn h-45 mb-2">Download</button>
          <div className="table-wrapper table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th className="text-center table-checkbox">  <Checkbox checked={selectAll} onChange={handleSelectAll} ></Checkbox> </th>
                  <th>S. No.</th>
                  <th>Batch Number</th>
                  <th>Date of Generation</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {(data.length > 0) ?
                  data.map((element, index) => {
                    return (
                      <tr key={startIndex + index + 1}>
                        <td className="text-center table-checkbox">
                          <Checkbox checked={selectedIds.includes(element._id)}
                            onChange={() => handleCheckboxChange(element._id)}>
                          </Checkbox>
                        </td>
                        <td>{startIndex + index + 1}</td>
                        <td>{element.batchNumber}</td>
                        <td className="ws-nowrap">{formatDate(element.createdAt)}</td>
                        <td className="ws-nowrap">
                          {
                            element.status === 1 ?
                              <span className="primary-badge text-uppercase mb-0">Printed</span> :
                              <span className="danger-badge text-uppercase mb-0">Not Printed</span>
                          }
                        </td>
                        <td className="ws-nowrap">
                          <div className="tableAction-wrapper">
                            <button
                              className="tableAction-btn btn-view"
                              onClick={() => {
                                navigate('/nylon/template?id=' + element._id)
                              }}
                            >
                              <AiFillEye />
                            </button>

                            <button className="tableAction-btn btn-download"
                              onClick={() => handleDownloadPdf(element._id)}>
                              <TbDownload />
                            </button>

                            <Popconfirm
                              title="Delete the task"
                              description="Are you sure to delete this batch?"
                              onConfirm={() => handleConfirm(element._id)}
                              onCancel={cancel}
                              okText="Yes"
                              cancelText="No"
                            >
                              <button className="tableAction-btn btn-delete">
                                <RiDeleteBin6Fill />
                              </button>
                            </Popconfirm>
                          </div>
                        </td>
                      </tr>
                    );
                  }) :
                  <tr>
                    <td colSpan="4" className="text-center">No record found!</td>
                  </tr>
                }

              </tbody>
            </table>
          </div>
        </div>
      </div>
      {(totalPages > 0) ?
        <div className="row">
          <div className="col-md-12">
            <div className="pagination-wrapper mb-2">
              <span className={currentPage > 1 ? 'active-span-bg pointer' : ''} onClick={() => {
                if (currentPage === 1) {
                  return false;
                }
                setCurrentPage(currentPage - 1);
              }}><FaLongArrowAltLeft className={currentPage > 1 ? 'active-span-svg' : ''} /></span>
              <span>{currentPage}/{totalPages}</span>
              <span className={currentPage == totalPages ? 'inactive-span-bg' : 'pointer'} onClick={() => {
                if (currentPage === totalPages) {
                  return false;
                }
                setCurrentPage(currentPage + 1);
              }}><FaLongArrowAltRight className={currentPage == totalPages ? 'inactive-span-svg' : ''} /></span>
            </div>
          </div>
        </div>
        : ''}
    </div>
  );
}

export default GeneratedNylons;
