import React, { useEffect, useState } from "react";
import { Form, Input, Upload, message } from "antd";
import { FiCamera } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { post } from "../../services/apiCall";
import { useNavigate } from "react-router-dom";
import { ActionTypes } from "../../redux/constants/ActionTypes";
import Loader from "../Loader";

function EditAdminProfile() {
  const [form] = Form.useForm();
  const userInfo = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();

  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the Enter key
    }
  };

  const handleBeforeUpload = (file) => {
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    return false; // Prevent default upload behavior
  };

  const handleEditForm = async (values) => {
    const formData = new FormData();
    formData.append('profileImage', selectedImage);

    for (let key in values) {
      formData.append(key, values[key]);
    }

    try {
      setLoading(true);
      const updateResponse = await post('/auth/update-profile', formData);
      if (updateResponse.status) {
        localStorage.setItem("userInfo", JSON.stringify(updateResponse.data));
        dispatch({
          type: ActionTypes.USER_LOGIN_SUCCESS,
          payload: updateResponse.data,
        });
        message.success(updateResponse.message);
      } else {
        message.error(updateResponse.message);
      }
    } catch (error) {
      console.error('Error logging in:', error);
    } finally {
      setLoading(false);
    }
  }



  return (
    <div className="form-card form-sm-custom-style">
      <Form
        form={form}
        name="editProfile"
        initialValues={{
          firstName: userInfo?.firstName,
          lastName: userInfo?.lastName,
          email: userInfo?.email,
          designation: userInfo?.roleInfo.roleName
        }}
        onFinish={handleEditForm}
        layout="vertical"
        autoComplete="on"
        requiredMark={false}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="d-inline-block mb-4 position-relative">
              {
                imagePreview ?
                  <img
                    name="profileImage"
                    src={imagePreview}
                    alt="user-img"
                    className="profileFrm-UserImg"
                  /> :
                  <img
                    src={`${userInfo?.imageBaseUrl + "/" + userInfo?.imageName}`}
                    alt="Preview"
                    className="profileFrm-UserImg"
                  />
              }

              <Upload
                showUploadList={false}
                accept="image/*"
                beforeUpload={handleBeforeUpload}
              >
                <span className="profileupload-camera">
                  <FiCamera />
                </span>
              </Upload>
            </div>
          </div>

        </div>
        <div className="row">
          <div className="col-md-6">
            <Form.Item
              onKeyDown={handleKeyDown}
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your First Name.",
                },
              ]}
            >
              <Input placeholder="Enter First Name" />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              onKeyDown={handleKeyDown}
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your Last Name.",
                },
              ]}
            >
              <Input placeholder="Enter Last Name" />
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Form.Item
              onKeyDown={handleKeyDown}
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input your email!",
                },
              ]}
            >
              <Input placeholder="Enter Email" />
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Form.Item
              onKeyDown={handleKeyDown}
              name="designation"
              label="Designation">
              <Input placeholder="Enter Designation" readOnly disabled />
            </Form.Item>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-12 py-4">
            <button
              type="submit"
              disabled={loading}
              className="form-btn"
            >{loading ? <Loader /> : "Save Changes"}</button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default EditAdminProfile;
