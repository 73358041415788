import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { Select, message } from "antd";
import { FiSearch, FiMapPin } from "react-icons/fi";
import { BiSolidDownArrow } from "react-icons/bi";
import { FaRegUser } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { BiPhone } from "react-icons/bi";
import { Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { get, post } from "../../../../services/apiCall";
import Loader from "../../../../components/Loader";
import VendorListAutoComplete from "../../../../components/VendorDetails/VendorListAutoComplete";

function AddVendorStaff() {
  const [form] = Form.useForm();
  const [vendorSelect, setVendorSelect] = useState(false);
  const breadcrumbItems = [
    { label: "Vendor Management" },
    { label: "All vendors", url: "/vendor/all" },
    { label: "Add Vendor Staff" },
  ];
  const [vendorData, setVendorData] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const selectedVendorID = (vendorData) => {
    setVendorData(vendorData);
    setVendorSelect(true);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if (values.password == undefined) {
        values.password = '';
      }
      values.vendorId = vendorData._id;
      const response = await post('/vendor/add-vendor-staff/', values);
      if (response.status) {
        form.resetFields();
        // navigate("/vendor/all");
        setVendorSelect(false);
        message.success(response.message);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error('Error in vendor staff registration:', error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="container-fluid">
      <Breadcrumbs items={breadcrumbItems} />
      <div className="row">
        <div className="col-lg-3 mb-2 my-2">
          <div className="antd-search-selector">
            <span className="search-icon">
              <FiSearch className="primary-color" />
            </span>
            <VendorListAutoComplete getSelectedVendorID={selectedVendorID} />
          </div>
          {
            vendorSelect ?
              <div className="panel-card my-3">
                <div className="card-body">
                  <p className="fw-500 heading-color fs-20">Vendor Company name</p>
                  {(vendorData.firstName || vendorData.lastName) ?
                    <p className="heading-color fs-16 mb-3">
                      <FaRegUser className="me-3 fs-20 primary-color" />
                      {vendorData.firstName} {vendorData.lastName}
                    </p>
                    : ''}
                  {(vendorData.email) ?
                    <p className="heading-color fs-16 mb-3">
                      <FiMail className="me-3 fs-20 primary-color" />
                      {vendorData.email}
                    </p>
                    : ''}
                  {(vendorData.phoneNumber) ?
                    <p className="heading-color fs-16 mb-3">
                      <BiPhone className="me-3 fs-20 primary-color" />
                      {vendorData.phoneNumber}
                    </p>
                    : ''}
                  {(vendorData.companyAddress) ?
                    <p className="heading-color fs-18 mb-0">
                      <FiMapPin className="me-3 fs-20 primary-color" />
                      {vendorData.companyAddress}
                    </p>
                    : ''}
                </div>
              </div> : ""
          }
        </div>
        <div className="col-lg-9 my-2">
          <div className="form-card form-sm-custom-style mb-4">
            <div className="form-heading">
              <p className="primary-color fw-500 fs-22 text-decoration-underline">Add Vendor staff</p>
            </div>
            <Form
              form={form}
              name="vendorStaff"
              initialValues={{
                remember: true,
              }}
              onFinish={handleSubmit}
              layout="vertical"
              autoComplete="on"
              requiredMark={false}
            >
              <div className="row">
                <div className="col-md-6">
                  <Form.Item name="firstName" label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter first name!",
                      },
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item name="lastName" label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter last name!",
                      },
                    ]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Form.Item name="email" label="Email Address"
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Please enter email!",
                      },
                    ]}
                  >
                    <Input placeholder="example@gmail.com" />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item name="phoneNumber" label="Phone Number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter phone number!",
                      },
                    ]}
                  >
                    <Input placeholder="+234 09-461-4000" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Form.Item name="password" label="Set Password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter password!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-12">
                  <button disabled={(vendorData._id) ? '' : 'disabled'} type="submit" className="form-btn">{loading ? <Loader /> : "Add Vendor Staff"}</button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddVendorStaff;
