import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { message } from "antd";
import { get } from "../../../services/apiCall";
import { useLocation } from "react-router-dom";
import BodyLoader from "../../../components/BodyLoader";

function NylonTemplate() {
  const breadcrumbItems = [
    { label: "Nylon Management" },
    { label: "Nylon Details", url: "/nylon/details?tab=generated" },
    { label: "Nylon View" }
  ];

  const location = useLocation();
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const bacthId = params.get('id');
  const [loading, setLoading] = useState(false);
  const [qrCodes, setQrCodes] = useState([]);
  const [qrBaseUrl, setQrBaseUrl] = useState();
  const [batchNumber, setBatchNumber] = useState();

  useEffect(() => {
    getQrCodesForABatchId();
  }, []);

  const getQrCodesForABatchId = async () => {
    setLoading(true);
    try {
      const result = await get(`/nylon/nylon-batch-items?batchId=${bacthId}`);
      if (result.status) {
        setQrCodes(result.data);
        setQrBaseUrl(result.imageBaseUrl);
        setBatchNumber(result.batchNumber);
      } else {
        message.error(result.message);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="container-fluid">
      {(loading) ? <BodyLoader /> : ''}
      <Breadcrumbs items={breadcrumbItems} />
      <div className="row gap-5 no-top-gap">
      <p className="titleQrPage">Batch Number: {batchNumber}</p>        
          {(qrCodes.length > 0) ?
            qrCodes.map((element, index) => {
              return (
                <div className="qrbox col-md-3 mb-5">
                  <p className="lText">MINISTRY OF ENVIRONMENT</p>
                  <img src="/images/dustbinIcon.png" alt="img2" />
                  <img src="/images/dustbinIcon.png" alt="img3" />
                  <img className="topImage" src="/images/topImg.png" alt="img4" />
                  <img className="qrImage" src={`${qrBaseUrl + "/" + element?.qrCodeImageName}`}
                    alt="scannerImg"/>
                  <p className="rText">KEPA WASTE BAG</p>
                  <p className="serial_code">{element?.serialNumber}</p>
                  <img className="footerImage" src="/images/bottomImage.png" alt="img5" />
                  <p className="hotLineNumber">Hoteline: <strong>0808-111-2222</strong></p>
                </div>

              );
            }) :
            <div className="col-md-3 my-4">
              <span>No record found!</span>
            </div>
          }
       
      </div>
    </div>
  );
}

export default NylonTemplate;
