import React, { useEffect, useState } from "react";
import TablePagination from "../Table/TablePagination";
import TableComponents from "../Table/TableComponents";
import { Link, useNavigate } from "react-router-dom";
import { get } from "../../services/apiCall";
import BodyLoader from "../BodyLoader";
import { message } from "antd";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";

function AssignedNylons() {

  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Change as needed
  const [totalPages, setTotalPages] = useState(0);
  const [searchData, setSearchData] = useState('');
  const [isAssigned, setIsAssigned] = useState(1);
  const [loading, setLoading] = useState(false)


  const handleDataForSearchTableComponent = (data) => {
    setSearchData(data);
    setItemsPerPage(15);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchAssignedNylonList();
  }, [currentPage, searchData]);

  const fetchAssignedNylonList = async () => {
    setLoading(true);
    try {
      const nylonsLists = await get(`/nylon/nylon-batch-listing?is_assigned=${isAssigned}&page=${currentPage}&limit=${itemsPerPage}&searchText=${searchData}`);
      if (nylonsLists.status) {
        setData(nylonsLists.data);
        setTotalPages(nylonsLists.pagination.totalPages);
      } else {
        message.error(nylonsLists.message);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {(loading) ? <BodyLoader /> : ''}
      <TableComponents sendDataToGenerateNylons={handleDataForSearchTableComponent} />
      <div className="row my-4">
        <div className="col-md-12">
          <div className="table-wrapper table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Batch Number</th>
                  <th>Vendor Code</th>
                  <th>Company Name</th>
                  <th>Assigned Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {(data.length > 0) ?
                  data.map((element, index) => {
                    return (
                      <tr key={index + 1}>
                        <td>{index + 1}</td>
                        <td>{element.batchNumber}</td>
                        <td>123456</td>
                        <td>Enumeral Waste Solutions Limited</td>
                        <td className="ws-nowrap">09/ 09/ 2023, 12:45 PM</td>
                        <td className="ws-nowrap">
                          <Link
                            to="/nylon/details/id"
                            className="primary-color fs-16 fw-500"
                          >
                            View Details
                          </Link>
                        </td>
                      </tr>
                    );
                  }) :
                  <tr>
                    <td colSpan="5" className="text-center">No record found!</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {(totalPages > 0) ? 
      <div className="row">
        <div className="col-md-12">
          <div className="pagination-wrapper mb-2">
            <span className={currentPage > 1 ? 'active-span-bg pointer' : ''} onClick={() => {
              if (currentPage === 1) {
                return false;
              }
              setCurrentPage(currentPage - 1);
            }}><FaLongArrowAltLeft className={currentPage > 1 ? 'active-span-svg' : ''} /></span>
            <span>{currentPage}/{totalPages}</span>
            <span className={currentPage == totalPages ? 'inactive-span-bg' : 'pointer'} onClick={() => {
              if (currentPage === totalPages) {
                return false;
              }
              setCurrentPage(currentPage + 1);
            }}><FaLongArrowAltRight className={currentPage == totalPages ? 'inactive-span-svg' : ''} /></span>
          </div>
        </div>
      </div>
      :''}
    </div>
  );
}

export default AssignedNylons;
