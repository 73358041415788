import React, { useState } from "react";
import { Form, Input, message } from "antd";
import { Link } from "react-router-dom";
import { passwordValidator } from "../../services/validation";
import { post } from "../../services/apiCall";
import Loader from "../Loader";

function ChangePassword() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const confirmPasswordValidator = (_, value) => {
    const passwordFieldValue = form.getFieldValue("newPassword");
    if (!value || passwordFieldValue === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Passwords do not match"));
  };

  const onHandleSaveChanges = async (values) =>{
    try {
      setLoading(true);
      const response = await post('/auth/update-password-with-current', values);
      if (response.status) {
        form.resetFields();
        message.success(response.message);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error('Error logging in:', error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="form-card form-sm-custom-style">
      <Form
        form={form}
        name="changePassword"
        initialValues={{
          remember: true,
        }}
        layout="vertical"
        onFinish={onHandleSaveChanges}
        autoComplete="on"
        requiredMark={false}
      >
        <div className="row">
          <div className="col-md-12">
            <Form.Item name="currentPassword" label="Current Password"
              rules={[
                {
                  required: true,
                  message: "Pleae enter current password!",
                  whitespace: true,
                }
              ]}>
              <Input.Password placeholder="Current Password" />
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Form.Item name="newPassword" label="New Password"
              rules={[
                {
                  required: true,
                  message: "Please enter new password!",
                  whitespace: true,
                },
                { validator: passwordValidator },
              ]}>
              <Input.Password placeholder="New Password" />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item name="confirmPassword" label="Confirm Password"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                {
                  validator: confirmPasswordValidator,
                },

              ]}>

              <Input.Password placeholder="Confirm Password" />
            </Form.Item>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-12 pt-4 mb-2">
            <button type="submit"
              className="form-btn">
              {loading ? <Loader /> : "Save Changes"}
            </button>
          </div>
        </div>
        <div className="col-md-12 text-start">
          <Link to="/forgot-password" className="fs-18 fw-500 primary-color">
            Forgot Password?
          </Link>
        </div>
      </Form>
    </div>
  );
}

export default ChangePassword;
